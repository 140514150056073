import {
  isInstallmentsPlanType,
  isListingPlanTypeSubscription,
} from "features/Listings/utils/Listings.utils";
import { useFetchListingDetails } from "features/Listings/utils/useFetchListingDetails";
import { logError } from "utils/error";

export const useCheckFeatureEntity = () => {
  const { fetchListingDetails } = useFetchListingDetails();

  /**
   * returns whether array of offerings has a subscription type of offering or not
   * @param selectedOfferingsUuid: array of offering uuids
   * @returns true/false
   */
  const checkFeatureEntity = async ({ selectedOfferingsUuid }) => {
    try {
      const result = { isSubscription: false, hasInstallments: false };
      for (const listingUuid of selectedOfferingsUuid) {
        const data = await fetchListingDetails({
          listingUuid,
        });
        const { payment_plan_type } = data;

        if (isListingPlanTypeSubscription(data)) {
          result.isSubscription = true;
        }
        if (isInstallmentsPlanType(payment_plan_type)) {
          result.hasInstallments = true;
        }
      }
      return result;
    } catch (error) {
      logError({
        error,
        when: "hasSubscriptionOffering",
        occuredAt:
          "src/features/AffiliateMarketing/utils/useCheckFeatureEntity.js",
      });
      return { isSubscription: false, hasInstallments: false };
    }
  };

  return { checkFeatureEntity };
};
