import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import {
  GET_PAYOUT_STATUS_API,
  GET_TRANSACTION_INVOICE_URL_API,
  INITIATE_COMMISSION_PAYMENT_API,
  RETRY_PAYMENT_API,
  RETRY_PAYOUT_API,
} from "features/AffiliateMarketing/constants/Affliates.api";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { initiateJuspay } from "features/Payment/utils/Payment.utils";
import useInvoiceViewer from "./useInvoiceViewer";
import { useDispatch } from "react-redux";
import { EXLY_AFFILIATE_MARKETING__SET_PROCESSING } from "../redux/Affliates.actions";
import { is_empty } from "features/Common/utils/common.utils";

export const usePayment = () => {
  const { notify } = useNotifications();
  const { getInvoiceURL } = useInvoiceViewer();
  const dispatch = useDispatch();

  const initiatePayment = async ({ requestId = "" }) => {
    if (is_empty(requestId)) return;
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
      payload: true,
    });
    try {
      const returnUrl = window.location.href.split("?")?.[0];
      const response = await dataProvider.custom_request(
        INITIATE_COMMISSION_PAYMENT_API,
        apiMethods.POST,
        {
          return_url: returnUrl,
          request_id: requestId,
        }
      );
      if (
        isRequestSuccessful(response?.status) &&
        !is_empty(response?.data?.order)
      ) {
        initiateJuspay(response.data.order);
      } else {
        notify(SOMETHING_WENT_WRONG, notification_color_keys.error);
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateDetail/utils/useAffiliateDetail.js",
        when: "initiatePayment ̰",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
        payload: false,
      });
    }
  };

  const getOrderStatus = async (orderId) => {
    try {
      if (is_empty(orderId)) return;
      const response = await dataProvider.custom_request(
        `${GET_PAYOUT_STATUS_API}/${orderId}`
      );
      const order = response?.data?.order;
      if (isRequestSuccessful(response?.status) && !is_empty(order)) {
        return order;
      }
      return null;
    } catch (error) {
      logError({
        error,
        when: "showOrderStatus",
        occuredAt: "src/features/AffiliateMarketing/utils/usePayment.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      return null;
    }
  };

  /**
   * retry payment if payment from creator to Exly failed
   * @param orderId: order id of the payment
   * @returns void. opens payment page
   */
  const handleRetryPayment = async ({ orderId }) => {
    try {
      if (is_empty(orderId)) return;
      const response = await dataProvider.custom_request(
        RETRY_PAYMENT_API,
        apiMethods.POST,
        { order_id: orderId }
      );

      if (
        isRequestSuccessful(response?.status) &&
        !is_empty(response?.data?.order)
      ) {
        initiateJuspay(response.data.order);
      } else {
        notify(SOMETHING_WENT_WRONG, notification_color_keys.error);
      }
    } catch (error) {
      logError({
        error,
        when: "handleRetryPayment",
        occuredAt: "src/features/AffiliateMarketing/utils/usePayment.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
  };

  /**
   * retry payment if payment from  Exly to affiliate failed
   * @param payoutUuid: payout id of the payment
   * @returns void. opens payment page
   */
  const handleRetryPayout = async ({ payoutUuid }) => {
    try {
      if (is_empty(payoutUuid)) return;
      const response = await dataProvider.custom_request(
        RETRY_PAYOUT_API,
        apiMethods.POST,
        { payout_uuid: payoutUuid }
      );

      if (isRequestSuccessful(response?.status)) {
        notify(response?.message, notification_color_keys.info);
      }
    } catch (error) {
      logError({
        error,
        when: "handleRetryPayment",
        occuredAt: "src/features/AffiliateMarketing/utils/usePayment.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
  };

  const getTransactionInvoiceUrl = async ({ affiliateUuid, payoutAmount }) => {
    try {
      if (is_empty(affiliateUuid)) return;
      const response = await dataProvider.custom_request(
        GET_TRANSACTION_INVOICE_URL_API,
        apiMethods.POST,
        { affiliate_payout_amount: payoutAmount, affiliate_uuid: affiliateUuid }
      );
      if (isRequestSuccessful(response?.status)) {
        const receiptUrl = response?.data?.receipt_url;
        getInvoiceURL(receiptUrl);
      }
    } catch (error) {
      logError({
        error,
        when: "getTransactionInvoiceUrl",
        occuredAt: "src/features/AffiliateMarketing/utils/usePayment.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
  };

  return {
    initiatePayment,
    getOrderStatus,
    handleRetryPayment,
    handleRetryPayout,
    getTransactionInvoiceUrl,
  };
};
