import { useEffect } from "react";
import { hasCreatorPermissions, isInternationalTimezone } from "utils/AuthUtil";
import { RESOURCE_KEYS } from "utils/OrgPermissions";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "../constants/Affliates.urlPaths";

export const useCheckAffiliateAccessible = () => {
  useEffect(() => {
    if (isInternationalTimezone()) {
      return window.location.replace("");
    }
    const check = hasCreatorPermissions(
      RESOURCE_KEYS.SECTIONS.AFFILIATE_MARKETING
    );
    if (!check) {
      navigateTo(AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing);
    }
  }, []);

  return;
};
