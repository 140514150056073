import { ExlyInput } from "common/form";
import moduleStyle from "./ManageSenderDetails.module.css";
import React from "react";
import { is_empty } from "utils/validations";

export const SenderDetailForm = ({
  formValues,
  setFormValues,
  errorObject,
  setErrorObject,
}) => {
  return (
    <div className={`py-4`}>
      <div className={`${moduleStyle.sectionTitle} mb-1`}>Email Details</div>
      <ExlyInput
        fieldLabel={{ text: "Sender Name", required: true }}
        fullWidth
        value={formValues?.custom_email_name}
        className={`mb-2`}
        onChange={(value) => {
          setErrorObject((error) => {
            delete error.custom_email_name;
            return error;
          });
          setFormValues({ ...formValues, custom_email_name: value });
        }}
        size="small"
        error={!is_empty(errorObject?.custom_email_name)}
        helperText={errorObject?.custom_email_name}
      />
      <ExlyInput
        fieldLabel={{ text: "Sender Email", required: true }}
        fullWidth
        value={formValues?.custom_from_email}
        size="small"
        onChange={(value) => {
          setErrorObject((error) => {
            delete error.custom_from_email;
            return error;
          });
          setFormValues({ ...formValues, custom_from_email: value });
        }}
        error={!is_empty(errorObject?.custom_from_email)}
        helperText={errorObject?.custom_from_email}
      />
    </div>
  );
};
