import { logError } from "utils/error";
import { sendRemainder } from "../modules/AffliateDetail/constants/sendRemainder.apiCalls";
import { is_empty } from "utils/validations";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { usePayoutModal } from "../modules/PayoutModal/utils/usePayoutModal";
import { isRequestSuccessful } from "utils/Utils";

export const useBankDetail = () => {
  const { notify } = useNotifications();

  const { handleShowAffiliatePayoutErrorModal } = usePayoutModal();
  const handleSendRemainder = async (affiliateUuid) => {
    if (is_empty(affiliateUuid)) return;
    try {
      const response = await sendRemainder({
        affiliate_uuid: affiliateUuid,
      });

      if (isRequestSuccessful(response?.status)) {
        handleShowAffiliatePayoutErrorModal(false);
      }
      notify(response?.message, notification_color_keys.info);
    } catch (error) {
      logError({
        error,
        when: "handleSendRemainder",
        occuredAt: "src/features/AffiliateMarketing/utils/useBankDetail.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
  };

  return { handleSendRemainder };
};
