import React from "react";
import { useFeatureAccessibility } from "features/Account/utils/useFeatureAccessibility";
import { FEATURE_KEYS } from "features/Account/Account.constants";
import { WhitelabelLock } from "features/Account/modules/Whitelabel/modules/WhitelabelLock";
import moduleStyle from "./ManageSenderDetails.module.css";
import ExlyModal from "common/Components/ExlyModal";
import { SenderDetailForm } from "./SenderDetailForm";
import { AUTH_KEYS } from "features/Common/modules/CreatorData/CreatorData.constants";
import { isValidEmail, is_empty } from "utils/validations";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useCreatorData } from "features/Common/modules/CreatorData/utils/useCreatorData";

export const ManageSenderDetails = () => {
  const { isFeatureAccessible } = useFeatureAccessibility();
  const { notify } = useNotifications();
  const { updateUserData, getUserData } = useCreatorData();

  const social_info = getUserData(AUTH_KEYS.social_info);

  const [showManageSenderDetailsModal, setShowManageSenderDetailsModal] =
    React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    custom_from_email: social_info?.custom_from_email ?? "",
    custom_email_name: social_info?.custom_email_name ?? "",
  });
  const [errorObject, setErrorObject] = React.useState({});

  const isWhitelabelActive = isFeatureAccessible({
    featureKey: FEATURE_KEYS.whitelabel,
  });

  const handleSave = () => {
    let error = {};
    if (is_empty(formValues?.custom_from_email)) {
      error.custom_from_email = "Required";
    }
    if (!isValidEmail(formValues.custom_from_email)) {
      error.custom_from_email = "Invalid";
    }
    if (is_empty(formValues?.custom_email_name)) {
      error.custom_email_name = "Required";
    }

    if (!is_empty(error)) {
      setErrorObject(error);
      return notify("Invalid form values!", notification_color_keys.error);
    }

    if (processing) return;
    setProcessing(true);
    updateUserData({
      updatedValues: { social_info: formValues },
      onSuccess: () => {
        setProcessing(false);
        setShowManageSenderDetailsModal(false);
      },
      onError: () => setProcessing(false),
    });
  };

  const onSenderDetailsClick = () => {
    if (isWhitelabelActive) {
      setShowManageSenderDetailsModal(true);
    } else {
      document.querySelector("#WhitelabelLock").click();
    }
  };

  return (
    <>
      <div
        className={`${moduleStyle.manageSenderDetails} d-flex align-items-center pointer`}
      >
        <div onClick={() => onSenderDetailsClick()}>Manage Sender Details</div>

        {!isWhitelabelActive && (
          <WhitelabelLock
            hideLabel
            modalDescription={{
              heading:
                "Edit Email & Whatsapp Sender Details via White-labelling",
            }}
          />
        )}
      </div>
      <ExlyModal
        open={showManageSenderDetailsModal}
        onClose={() => setShowManageSenderDetailsModal(false)}
        primaryBtnText={processing ? "..." : "Done"}
        modal_props={{
          modalPaperClassName: moduleStyle.desktopModalPaper,
        }}
        onPrimaryBtnClick={handleSave}
        title={"Edit Sender Details"}
      >
        <SenderDetailForm
          formValues={formValues}
          setFormValues={setFormValues}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
        />
      </ExlyModal>
    </>
  );
};
