import { useEffect, useMemo, useState } from "react";
import { date_range_select_options } from "../constants/AffiliateMarketing.constants";
import { is_empty } from "features/Common/utils/common.utils";
import { formatDateRange, getDateRange } from "./AffiliateMarketing.utils";

export const useDateRangeSelector = ({ fetchStatsData }) => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedRange, setSelectedRange] = useState(null);

  // initial value of date range selector
  useEffect(() => {
    onRangeChange(date_range_select_options.last_7.value);
  }, []);

  useEffect(() => {
    if (
      !is_empty(dateRange?.startDate) &&
      !is_empty(dateRange?.endDate) &&
      selectedRange === date_range_select_options.custom.value
    ) {
      fetchStatsData({
        params: {
          start_date: dateRange?.startDate,
          end_date: dateRange?.endDate,
        },
      });
    }
  }, [dateRange, selectedRange]);

  const onRangeChange = (value) => {
    setSelectedRange(value);
    const { startDate, endDate } = getDateRange(value);
    if (value !== date_range_select_options.custom.value) {
      setDateRange({ startDate, endDate });
      fetchStatsData({ params: { start_date: startDate, end_date: endDate } });
    } else {
      setDateRange({ startDate: null, endDate: null });
    }
  };

  // get range form in string on change on date range
  const getFormattedDateRange = useMemo(() => {
    return dateRange?.startDate && dateRange?.endDate
      ? formatDateRange(dateRange?.startDate, dateRange?.endDate)
      : "";
  }, [selectedRange, dateRange]);

  return {
    onRangeChange,
    dateRange,
    selectedRange,
    getFormattedDateRange,
    setDateRange,
  };
};
