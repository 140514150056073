import { is_empty } from "features/Common/utils/common.utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES } from "../redux/Affliates.actions";

export const useSetCommissionFormInitialValues = ({ initialValues }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!is_empty(initialValues))
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
        payload: initialValues,
      });
  }, [initialValues]);

  return true;
};
