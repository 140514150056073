import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { getPathFromS3URL } from "features/Common/modules/S3/utils/S3.utils";
import { useState } from "react";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { getS3SignedUrl } from "utils/Utils";

const useInvoiceViewer = () => {
  const { notify } = useNotifications();
  const [loading, setLoading] = useState(false);

  const getInvoiceURL = async (url) => {
    try {
      if (loading) return;
      setLoading(true);
      const invoicePath = getPathFromS3URL(url);
      const signedUrl = await getS3SignedUrl(invoicePath);

      window.open(signedUrl);
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      logError({
        error,
        occuredAt:
          "src/features/AffiliateMarketing/modules/PayoutSummary/modules/InvoiceField/InvoiceField.jsx",
        when: "getInvoiceURL",
      });
    } finally {
      setLoading(false);
    }
  };

  return { getInvoiceURL, loading };
};

export default useInvoiceViewer;
